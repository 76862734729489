import CheckboxSelectAll from "stimulus-checkbox-select-all";
import Rails from "@rails/ujs";
import Swal from "sweetalert2";

export default class extends CheckboxSelectAll {
  enroll(event) {
    event.preventDefault();

    let user_id = event.currentTarget.dataset.attribute;

    Swal.fire({
      title: "Uwaga!",
      text: "Czy jesteś pewien, że chcesz przypisać wybrane kursy pracownikowi?",
      icon: "warning",
      confirmButtonText: "Tak, przypisz",
      showCancelButton: true,
      cancelButtonText: "Anuluj",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = new FormData();
        data.append("user_id", user_id);
        this.checked.forEach((checkbox) =>
          data.append("ids[]", checkbox.value)
        );

        Rails.ajax({
          url: `users/${user_id}/bulk_enroller#create`,
          type: "POST",
          data: data,
        });
      }
    });
  }
}
