import CheckboxSelectAll from "stimulus-checkbox-select-all";
import Rails from "@rails/ujs";
import Swal from "sweetalert2";

export default class extends CheckboxSelectAll {
  enroll(event) {
    event.preventDefault();

    let course_id = event.currentTarget.dataset.attribute;

    let data = new FormData();
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value));

    if (this.checked.length !== 0) {
      Rails.ajax({
        url: `/learning/courses/${course_id}/enroll`,
        type: "POST",
        data: data,
      });
    } else {
      alert("Należy wybrać przynajmniej jedną osobę.");
    }
  }
}
