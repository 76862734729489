import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    path: String,
  };
  static targets = ["list", "info"];

  connect() {
    $.ajax({
      url: this.pathValue,
      success: (data) => {
        this.listTarget.innerHTML = data.html;
      },
      error: (_error) => {
        this.infoTarget.innerHTML = "Błąd pobierania.";
      },
    });
  }
}
