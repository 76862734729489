import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    path: String,
  };

  connect() {
    $.ajax({
      url: this.pathValue,
      success: (data) => {
        this.element.innerHTML = data.html;
      },
      error: (_error) => {
        this.element.innerHTML = "Błąd";
      },
    });
  }
}
