// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "select2";

import "datatables.net/js/jquery.dataTables";
import "jszip/dist/jszip";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4";

import "bootstrap-datepicker";
import axios from "axios";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "controllers";
import "codebase/app";
import "bootstrap/js/src/collapse";
import "./sweetalert";
import "multiselect";
import "jquery.quicksearch";

$(document).on("turbolinks:load", function () {
  // Add spinner when waiting for download
  const loadingSpinner = document.querySelector("#loading-spinner");

  document.querySelectorAll(".download-xlsx").forEach((element) =>
    element.addEventListener("click", function (event) {
      const MAX_LENGTH = 30;
      const xlsLink = event.target.getAttribute("data-link");
      const filename = event.target
        .getAttribute("data-filename")
        .substring(0, MAX_LENGTH);
      const suffix = event.target.getAttribute("data-suffix");

      loadingSpinner.classList.toggle("d-none");

      axios({
        method: "get",
        url: xlsLink,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename.concat(suffix));
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => loadingSpinner.classList.toggle("d-none"));
    })
  );

  $(".js-select2").select2();
  const $table = $(".js-datatable");
  const exportButtonsValue = $table.data("table-buttons") === false ? "" : "B";

  const table = $table.DataTable({
    lengthMenu: [
      [10, 25, 50, 100, -1],
      [10, 25, 50, 100, "Wszystkie"],
    ],
    language: {
      processing: "Przetwarzanie...",
      search: "Szukaj:",
      lengthMenu: "Pokaż _MENU_ pozycji",
      info: "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
      infoEmpty: "Pozycji 0 z 0 dostępnych",
      infoFiltered: "(filtrowanie spośród _MAX_ dostępnych pozycji)",
      infoPostFix: "",
      loadingRecords: "Wczytywanie...",
      zeroRecords: "Nie znaleziono pasujących pozycji",
      emptyTable: "Brak danych",
      paginate: {
        first: "Pierwsza",
        previous: "Poprzednia",
        next: "Następna",
        last: "Ostatnia",
      },
      aria: {
        sortAscending: ": aktywuj, by posortować kolumnę rosnąco",
        sortDescending: ": aktywuj, by posortować kolumnę malejąco",
      },
    },
    columnDefs: [
      {
        searchable: false,
        orderable: false,
        targets: 0,
      },
    ],
    order: [[1, "asc"]],
    dom:
      `<'row'<'col-sm-6'l><'col-sm-4'f>${exportButtonsValue}>` +
      "rt" +
      "<'row'<'col-sm-8'i><'col-sm-4'p>>",
    buttons: [
      {
        text: "Eksport (.xlsx)",
        extend: "excelHtml5",
        title: $table[0]
          ? `wyniki.${$table
              .data("table-title")
              .toLowerCase()
              .replace(/ /g, "_")}`
          : "fiblearning_eksport_wynikow",
      },
    ],
  });

  // This function is to ensure after sorting and searching,
  // that the lines are numbered in the correct order
  table
    .on("order.dt search.dt", function () {
      table
        .column(0, { search: "applied", order: "applied" })
        .nodes()
        .each(function (cell, i) {
          cell.innerHTML = i + 1;
        });
    })
    .draw();

  const simplestTable = $(".js-simplest-searchable-datatable").DataTable({
    pageLength: 500,
    language: {
      search: "Szukaj:",
      zeroRecords: "Nie znaleziono pasujących pozycji",
      emptyTable: "Brak danych",
      aria: {
        sortAscending: ": aktywuj, by posortować kolumnę rosnąco",
        sortDescending: ": aktywuj, by posortować kolumnę malejąco",
      },
    },
    dom: "<'row'<'pl-20'f>>" + "<'row'<'col-sm-12'rt>>",
  });

  // Destroy tables before caching by turbolinks
  $(document).on("turbolinks:before-cache", function () {
    if (table) {
      table.destroy();
    }

    if (simplestTable) {
      simplestTable.destroy();
    }
  });

  // Clicking table row changes state of the checkbox

  $(".js-clickable-table-row").click(function (event) {
    if ($(event.target).is('input[type="checkbox"]')) {
      return;
    }

    $(this).find('input[type="checkbox"]').click();
  });

  // Selects all checkboxes in a table

  $(".js-select-all").click(function (event) {
    var $table = $(this).closest("table");
    var $checkboxes = $table.find('input[type="checkbox"]');
    var state = $(this).prop("checked");
    $checkboxes.prop("checked", state);
  });

  // Selects all checkboxes in all tables

  $(".js-select-all-among-all").click(function (event) {
    var $checkboxes = document.getElementsByClassName("js-select-all");
    // convert HTML collection to array
    $checkboxes = Array.from($checkboxes);
    var state = $(this).prop("checked");
    $checkboxes.forEach(function (checkbox) {
      if (checkbox.checked !== state) {
        checkbox.click();
      }
    });
  });

  $("form").each(function () {
    let $spinner = $(this).find(".js-spinner");

    if ($spinner) {
      $spinner.hide();

      $(this).submit(function () {
        $spinner.show();
      });
    }
  });

  $(".multiselect").multiSelect({
    selectableHeader:
      "<input type='text' class='search-input' autocomplete='off' placeholder='Wyszukaj dostępne'>",
    selectionHeader:
      "<input type='text' class='search-input' autocomplete='off' placeholder='Wyszukaj wybrane'>",
    afterInit: function (ms) {
      var that = this,
        $selectableSearch = that.$selectableUl.prev(),
        $selectionSearch = that.$selectionUl.prev(),
        selectableSearchString =
          "#" +
          that.$container.attr("id") +
          " .ms-elem-selectable:not(.ms-selected)",
        selectionSearchString =
          "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected";

      that.qs1 = $selectableSearch
        .quicksearch(selectableSearchString)
        .on("keydown", function (e) {
          if (e.which === 40) {
            that.$selectableUl.focus();
            return false;
          }
        });

      that.qs2 = $selectionSearch
        .quicksearch(selectionSearchString)
        .on("keydown", function (e) {
          if (e.which == 40) {
            that.$selectionUl.focus();
            return false;
          }
        });
    },
    afterSelect: function () {
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function () {
      this.qs1.cache();
      this.qs2.cache();
    },
  });
});

$(document).on("focus", ".js-bootstrap-datepicker", function () {
  $(this).datepicker({
    language: "pl",
    format: $(this).data("dateFormat"),
    startDate: $(this).data("startDate"),
    endDate: $(this).data("endDate"),
    minViewMode: $(this).data("minViewMode"),
    maxViewMode: 3,
    autoclose: true,
  });
});

// Destroy javascript generated html, so turbolinks won't cache it
document.addEventListener("turbolinks:before-cache", function () {
  $(".js-select2").select2("destroy");
  $(".multiselect").multiSelect("destroy");
});

//Select employee for generate permission
$(document).on("turbolinks:load", function () {
  $(".js-toggle-employees-modal").click(function () {
    $("#EmployeeModal").removeClass("d-none").addClass("d-inline-block");
  });

  $(".js-toggle-employees-modal-hidden").click(function () {
    $("#EmployeeModal").removeClass("d-inline-block").addClass("d-none");
    $("#EmployeeModal input[type=checkbox]").prop("checked", false);
  });

  $(".js-export-selected-employees").click(function () {
    let selectedEmployeeIds = [];
    $("input[type=checkbox]").each(function () {
      if ($(this).is(":checked")) {
        selectedEmployeeIds.push($(this).val());
      }
    });

    let type = $(this).data("type");
    let format = "docx";

    const encodedIds = selectedEmployeeIds
      .map((id) => encodeURIComponent(id))
      .join(",");
    const encodedType = encodeURIComponent(type);

    const url = `/generate_employees/${type}?ids=${encodedIds}&format=${format}`;
    window.location.href = url;
  });
});
